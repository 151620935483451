import { useEffect } from 'react';
import gsap from 'gsap';

import Close from '../assets/images/close.png'
import acervoPopup from '../assets/images/popup_acervo.png'
import logo from '../assets/images/logo.png'
import image1 from '../assets/images/popup/image-1.png'
import image2 from '../assets/images/popup/image-2.png'
import image3 from '../assets/images/popup/image-3.png'

import { FaArrowRight } from "react-icons/fa";

export default function Popup() {
  const scrollToContact = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }

  const handeClick = () => {
    scrollToContact();
    closePopUp();
  }

  function closePopUp() {
    document.querySelector("#popup").setAttribute("data-active", 'none')
  }


  useEffect(() => {
    gsap.fromTo('#popup', {
      opacity: 0
    }, {
      opacity: 1,
      delay: 1
    })
  })

  return (
    <>
      {/* <div id='popup' data-active="active" className="bg-black/30 fixed top-0 left-0 w-full h-full z-50 hidden place-items-center  data-[active=active]:grid data-[active=none]:hidden " onClick={closePopUp}>
        <div className="flex flex-col justify-center w-[90%] md:w-[60%] h-[70%] bg-white p-2 md:p-4 items-center">
          <div className='relative flex flex-col w-full h-full items-center justify-between text-center p-2 drop-shadow-sm text-white rounded-sm'>
            <div className='bg-[#8ca8bc] w-full py-2 pt-3 flex flex-col items-center justify-center'>
                <h1 className='z-10 text-4xl font-bold drop-shadow-md'>ÚLTIMAS UNIDADES</h1>
                <h2 className='z-10 text-2xl font-light drop-shadow-md mb-3'>A partir de R$1.290.000</h2>
            </div>
            <div className='relative flex flex-col items-center justify-center w-full h-full overflow-hidden'>
                <p className='text-[#8ca8bc] text-2xl py-[10px] mb-[10px] text-center bg-white absolute -right-[10rem] top-6 md:-right-[7rem] md:top-8 w-[500px] rotate-[25deg] z-20 font-bold drop-shadow-sm'>75% VENDIDO</p>
                <img src='/VillaLogo.png' alt='Villa Logo' className='w-2/3 md:w-1/4 z-10 drop-shadow-md' /> 
                <p className='z-10 drop-shadow-md px-2 text-xl '>Condição exclusiva: 30% do valor apenas na entrega das chaves.</p>
                <a href='https://villamozak.com.br/' className='z-10 mb-3 mt-4 px-3 py-1 transition-all bg-white drop-shadow-md font-bold rounded-full hover:bg-black hover:text-white text-black flex hover:gap-2 gap-0 group items-center'>SAIBA MAIS<FaArrowRight className="w-0 transition-all group-hover:w-3 h-3" /></a>
                <div src='/popup/villa.webp' alt='Villa' className='absolute inset-0 bg-black/20 z-[2]' ></div>
                <img src='/popup/villa.webp' alt='Villa' className='w-full h-full absolute top-0 left-0 object-cover mb-3' />
            </div>
              <img id='close-popup' className="w-[12px] aspect-square object-contain absolute invert right-0 md:-right-8 -top-8 z-50 cursor-pointer" src={Close} onClick={closePopUp} alt="Fechar" />
            </div>
        </div>
      </div> */}

      <div
        id='popup'
        data-active="active"
        className="bg-black/30 fixed top-0 left-0 w-full h-full z-50 hidden place-items-center data-[active=active]:grid data-[active=none]:hidden px-4 py-4"
        onClick={closePopUp}
      >
        <div style={{backgroundImage: window.innerWidth < 1024 ? "linear-gradient(to right, rgba(0,0,0,0.7) 0%, transparent 100%), url('./popup/popupFundo.webp')" : "url('./popup/popupFundo.webp')"}} className="max-w-[850px] aspect-[4_/_5] md:aspect-auto 2xl:aspect-square bg-[85%] md:bg-cover w-full mx-auto flex flex-col justify-between relative max-h-[769px] lgh:h-4/5 2xl:h-auto overflow-auto">
          <div className='flex flex-col w-2/3 md:w-1/2 items-start 2xl:gap-0 gap-5 h-full py-10 text-white justify-around pl-5 md:px-16'>
            <div className='flex flex-col items-start gap-1 2xl:gap-2 '>
              <h1 className='2xl:text-5xl text-2xl md:text-4xl text-nowrap font-semibold uppercase'>Verão no Rio</h1>
              <h2 className='text-base md:text-xl 2xl:text-2xl text-nowrap text-[#DBC095]'>Viva o Melhor da estação<br/> em um Mozak</h2>
            </div>
            <p className='2xl:text-base text-xs md:text-sm'>Cenário de momentos incríveis, o Rio pode ser ainda mais inesquecível no verão. A Mozak está presente nas melhores localizações da zona sul e quer fazer parte desses momentos com você.</p>
            <ul className='border-l-[1px] text-xs md:text-base 2xl:text-xl border-white pl-4 list-none'>
              <li>
                Leblon
              </li>
              <li>
                Ipanema
              </li>
              <li>
                Jardim Botânico
              </li>
              <li>
                Gávea
              </li>
              <li>
                Botafogo
              </li>
            </ul>
            <a className='bg-[#F4F4F4] text-center rounded-full px-5 py-2 md:px-6 md:py-3 md:text-base text-sm text-black' href='#footer'>Fale com nossos especialistas e saiba mais</a>

            {/*  <div className='mt-[71px] flex flex-col gap-6 md:gap-2 lg:gap-6 mdh:gap-2 mdh:mt-4 lgh:mt-10 lgh:gap-6'>
              <div className='grid grid-cols-[1fr_2fr] md:grid-cols-[295px_1fr] gap-2 lg:gap-6 mdh:gap-2 lgh:gap-6'>
                <div className='md:px-12 flex justify-center flex-col'>
                  <p className='md:text-2xl tracking-[0.2em]'>O ACERVO</p>
                  <img src={logo} alt="Mozak" className='w-[72px] md:w-[100px] h-[17px] object-contain my-2' />
                  <p className='md:text-2xl tracking-[0.2em]'>ESTÁ <br /> CHEGANDO <br /> AO FIM</p>
                </div>

                <img src={image1} alt="" className='h-full object-cover lg:h-auto mdh:w-full lgh:w-auto' />
              </div>

              <div className='grid md:grid-cols-[295px_1fr] gap-6 md:gap-2 lg:gap-6 mdh:gap-2 lgh:gap-6'>
                <img src={image2} alt="" className='w-full object-cover' />

                <div className='md:grid md:grid-cols-[2fr_1fr] lg:flex'>
                  <img src={image3} alt="" className='object-cover w-full lg:w-auto md:h-full lg:h-auto' />
                  <p className='font-medium text-xl flex justify-center items-center w-full tracking-widest mt-4 md:mt-0 text-center'>
                    Aproveite <br className='hidden md:block' /> as últimas <br className='hidden md:block' /> unidades <br className='hidden md:block' /> com valores <br className='hidden md:block' /> de 2024
                  </p>
                </div>
              </div>
            </div>

            <div className='text-center my-6 lg:my-[71px] mdh:my-2 lgh:my-8'>
              <p className='text-lg tracking-widest'>Essa é a última oportunidade para você investir em um Mozak. <br /> Unidades estúdio, 1, 2 e 3 quartos com entrega em 2025! <br /> Fale agora com a Mozak</p>
            </div>*/}
          </div>
          {/*
            <div className='bg-[#282828] py-3 flex items-center justify-center mdh:py-2 lgh:py-3'>
              <a href='#footer' className='text-white font-bold'>FALE COM A MOZAK E SAIBA MAIS</a>
            </div>
            */}
          <img
            id='close-popup'
            className="w-[16px] invert drop-shadow-md aspect-square object-contain absolute right-4 top-4 z-50 cursor-pointer left-4"
            src={Close}
            onClick={closePopUp}
            alt="Fechar"
          />
        </div>
      </div>
    </>
  );
}